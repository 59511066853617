import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/mousewheel";
import "swiper/css/autoplay";
import { banners } from "../../consts/adBanners";

import "./styles.scss";

const AdCarousel = () => {
  return (
    <div className="ad-carousel">
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Mousewheel]}
        initialSlide={1}
        loop={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        grabCursor={true}
        slidesPerGroupSkip={1}
        slidesPerGroup={1}
        spaceBetween={12}
      >
        {banners.map((banner, index) => (
          <SwiperSlide key={index}>
            <img
              alt={banner.alt}
              src={banner.src}
              className="ad-carousel__banner"
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AdCarousel;
