import React, { useEffect, useState } from "react";
import Slider from "react-slider";

import "./styles.scss";
import GreenButton from "@Common/GreenButton";
import Loader from "@Common/Loader";
import InitLoader from "./InitLoader";
import Carousel from "./Carousel";
import {
  getCalculatedDataByParams,
  getInitialCalcData,
  getRedirectToServiceLinkByParams,
} from "@services/api";

import { useAuthState } from '@/contexts/AuthContext';

import formatPriceToMx from "@utils/formatPriceToMx";

import discount from "@/assets/images/discount.svg"
import calendar from "@/assets/images/calendar.svg"

import AdCarousel from "@/components/AdCarousel";

const CalculatorBlock = ({text, image}) => {
  return (
    <div className="calculator-blocks-item">
      <img alt="info" src={image} />
      <p>{ text }</p>
    </div>
  )
}

const defaultCalculatorInfo = {
  interestRate: 0.0,
  allFeeSum: 0,
  loanPrinciple: 0,
  LoanTenor: 0,
  vat: 0,
  organizationalFee: 0,
  paymentDt: "",
  PlatformFee: 0,
  PlatformFeeNoDiscount: 0,
  totalToPay: 0.0,
};

const CalculatorSection = React.forwardRef((props, ref) => {
  const { isLoading } = useAuthState();
  
  const [isFetching, setIsFetching] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [minSum, setMinSum] = useState(100);
  const [maxSum, setMaxSum] = useState(200);
  const [currentAmount, setCurrentAmount] = useState(100);
  const [currentTerm] = useState(30);
  const [amountStep, setAmountStep] = useState(10);
  const [calculatorInfo, setCalculatorInfo] = useState(defaultCalculatorInfo);

  const redirectToServicePage = async (e) => {
    // _paq.push(['trackEvent', 'Event', 'Button', 'Begin']); sendOrder()
    if (isFetching || !initialized) return;
    e.preventDefault();
    setIsFetching(true);
    try {
      const { link } = await getRedirectToServiceLinkByParams({ Amount: currentAmount, Term: currentTerm });
      window.location.assign(link);
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };


  const getInitialData = async () => {
    try {
      const data = await getInitialCalcData();
      setAmountStep(data.amountStep);
      setCurrentAmount(data.defaultAmount);
      setMinSum(data.amounts.minSum);
      // setMaxSum(data.amounts.maxSum);
    } catch (e) {
      console.error(e);
    } finally {
      setInitialized(true);
    }
  };

  const getCalculatedData = async () => {
    setIsFetching(true);
    try {
      const data = await getCalculatedDataByParams({
        Amount: currentAmount,
        Term: currentTerm,
      });

      setCalculatorInfo((prev) => ({
        ...prev,
        ...data,
      }));
    } catch (e) {
      console.error(e);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if ( !isLoading ) {
      getInitialData();
    }
  }, [isLoading]);

  useEffect(() => {
    if (initialized) getCalculatedData();
  }, [initialized]);

  return (
    <section ref={ref} className="container-width-100 ">
      <div className="block-1 container-page" id="block-1">
        <Carousel />
        <div className="calculator-slider">
          {!initialized ? <InitLoader /> : (
            <>
              <h2>Préstamo Personal Online</h2>
              <p className="title">Las 24 horas desde cualquier parte del Perú</p>
              <div className="calculator">

                <div className="calculator__sum">
                  <div className="calculator__sum__text-top">
                    <p className="body_m-bold">Elije la cantidad</p>
                    <p
                      className="body_l-bold"
                      id="calculator__sum"
                    >
                      {formatPriceToMx(currentAmount)}
                    </p>
                  </div>

                  <Slider
                    className="calculator-custom-slider"
                    trackClassName="calculator-custom-slider-track"
                    thumbClassName="calculator-custom-slider-thumb"
                    min={minSum}
                    max={maxSum}
                    step={amountStep}
                    defaultValue={currentAmount}
                    value={currentAmount}
                    onChange={(val) => setCurrentAmount(val)}
                    onAfterChange={(v) => getCalculatedData()}
                  />

                  <div className="body_s-regular calculator__sum__text-bottom">
                    <p id="calculator__sum-pip-left">{formatPriceToMx(minSum)}</p>
                    <p id="calculator__sum-pip-right">{formatPriceToMx(maxSum)}</p>
                  </div>
                  <p className="calculator__sum__prompt">
                    Podrás obtener hasta S/. 400 a partir de tu segundo préstamo
                  </p>
                </div>

                <div className="calculator__info text-center">
                  {isFetching && (
                    <div className="calculator__info-loader">
                      <Loader />
                    </div>
                  )}
                  {!isFetching && (
                    <>

                      <div className="main-info">
                        <div className="d-flex justify-between">
                          <p>Monto a pagar</p>
                          <p className="body_s-bold">{formatPriceToMx(calculatorInfo?.totalToPay)}</p>
                        </div>
                        <div className="d-flex justify-between">
                          <p>Primera fecha de pago</p>
                          <p className="body_s-bold">{new Date( Date.now() + 1000 * 60 * 60 * 24 * 30 ).toLocaleDateString()}</p>
                        </div>
                      </div>

                      <div className="calculator__button body_m-extrabold">
                        <GreenButton
                          disabled={isFetching}
                          onClick={(e) => redirectToServicePage(e)}
                        >
                          Solicita tu Crédito
                        </GreenButton>
                      </div>

                      <div className="tips">
                        <div className="d-flex justify-between">
                          <p>Interés</p>
                          <p className="body_l-bold">{formatPriceToMx(calculatorInfo?.interestRate)}</p>
                        </div>
                        <div className="d-flex justify-between">
                          <p>Comisión de Administración</p>
                          <p className="body_l-bold">{formatPriceToMx(calculatorInfo?.organizationalFee)}</p>
                        </div>
                        <div className="d-flex justify-between">
                          <p>IGV</p>
                          <p className="body_l-bold">18%</p>
                        </div>
                      </div>
                    </>
                  )}

                </div>
              </div>

              <AdCarousel />

              <div className="calculator-blocks" >
                <CalculatorBlock image={discount} text="Tasa anual desde 225% hasta 438%" />
                <CalculatorBlock image={calendar} text="Plazo de préstamo de 62 días a 180 días con posibilidad de reembolso anticipado" />
              </div>
            </>
          )}

        </div>
        <div className="error-message" id="calc-error">
          <p></p>
        </div>
        <div className="breakpoint-1" id="block-3"></div>
      </div>
    </section>
  );
});

export default CalculatorSection;
